<template>
  <div class="content-item left-content">
    <div class="top-title">
      <img src="../../assets/image/student/tag_left.png" alt="">
      <span class="top-text">学生答案情况</span>
      <img src="../../assets/image/student/tag.png" alt="">
    </div>
    <div class="script-content" id="studentContent">
      <!--            <el-scrollbar>-->
      <!--                <div class="script-item-content" id="studentAnswer">-->
      <!--                    <div class="script-item" v-for="(item,index) in answerData" :key="index">-->
      <!--                        <div :class="answerIndex >= index?'title-content active-title':'title-content'"-->
      <!--                             :id="`answerTitle_${index}`" ref="answerTitle">-->
      <!--                            <div class="title-left" v-if="index > 0">{{index+1}}</div>-->
      <!--                            <div class="title-left" v-else>-->
      <!--                                <i class="el-icon-right"></i>-->
      <!--                            </div>-->
      <!--                            <div class="title-right">{{item.title}}</div>-->
      <!--                        </div>-->
      <!--                        <div class="children-content">-->
      <!--                            <div class="script-subItem" v-for="(subItem,subIndex) in item.content"-->
      <!--                                 :key="subIndex"-->
      <!--                                 :id="`answerItem_${index}_${subIndex}`"-->
      <!--                                 :ref="`answerItem_${index}_${subIndex}`">-->
      <!--                                <div class="subItem-time">{{subItem.begin_time}}</div>-->
      <!--                                <span class="subItem-main" v-html="subItem.student_content"></span>-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </el-scrollbar>-->
      <div class="script-title">话术：</div>
      <el-scrollbar class="script-inner">
        <div class="inner-keywords">答对关键词:
          <span class="color-word">{{studentInfo.right_keyword}}个</span>
        </div>
        <div class="inner-main">{{studentInfo.studentContent}}</div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import {getStuScript} from '@/utils/apis'

export default {
  name: "StudentAnswer",
  props: ['stateObj', 'studentInfo'],
  data() {
    return {
      answerData: [],
      answerIndex: 0,
      timer: '',
      currentChildIndex: 0,
    }
  },
  mounted() {
    // this.getStuScriptList()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    cleanStatus() {
      if (this.stateObj.index != -1) {
        this.$refs[`answerItem_${this.stateObj.index}_${this.stateObj.subIndex}`][0].classList.remove('isCurrent')
      }
    },
    changeStatus() {
      clearInterval(this.timer)
      if (this.currentChildIndex > 0) {
        this.$refs[`answerItem_${this.answerIndex}_${this.currentChildIndex - 1}`][0].classList.remove('isCurrent')
      } else {
        this.$refs[`answerItem_${this.answerIndex}_${this.currentChildIndex}`][0].classList.remove('isCurrent')
      }
      this.$refs[`answerItem_${this.stateObj.index}_${this.stateObj.subIndex}`][0].classList.add('isCurrent')
      setTimeout(() => {
        this.$el.querySelector(`#answerItem_${this.stateObj.index}_${this.stateObj.subIndex}`).scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block: "start"  // 上边框与视窗顶部平齐。默认值
        });
      }, 500)
    },
    getStuScriptList() {
      getStuScript().then(res => {
        this.answerData = res.data.student
        // this.$nextTick(() => {
        //     this.currentChildIndex = 0
        //     this.timer = setInterval(() => {
        //         let parentLen = this.answerData.length
        //         let currentLen = this.answerData[this.answerIndex].content.length
        //         this.$el.querySelector(`#answerItem_${this.answerIndex}_${this.currentChildIndex}`).scrollIntoView({
        //             behavior: "smooth",  // 平滑过渡
        //             block: "start"  // 上边框与视窗顶部平齐。默认值
        //         });
        //         this.$refs[`answerItem_${this.answerIndex}_${this.currentChildIndex}`][0].classList.add('isCurrent')
        //         if (this.currentChildIndex > 0) {
        //             this.$refs[`answerItem_${this.answerIndex}_${this.currentChildIndex - 1}`][0].classList.remove('isCurrent')
        //         }
        //         if (this.currentChildIndex == currentLen - 1) {
        //             if (this.answerIndex == parentLen - 1) {
        //                 clearInterval(this.timer)
        //             } else {
        //                 setTimeout(() => {
        //                     this.answerIndex++
        //                     this.$refs[`answerItem_${this.answerIndex - 1}_${currentLen - 1}`][0].classList.remove('isCurrent')
        //                 }, 900)
        //                 this.currentChildIndex = 0
        //             }
        //         } else {
        //             this.currentChildIndex++
        //         }
        //     }, (res.data.student[this.answerIndex].content[this.currentChildIndex].end_timestamp) * 1000)
        // })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content-item {
  border-radius: 20px;
}

.left-content {
  margin-right: 20px;
  width: 40%;
  background: #0C0E3F;
  display: flex;
  flex-direction: column;
}

.top-title {
  margin-top: 42px;
  text-align: center;

  img {
    width: 41px;
    height: 15px;
  }

  .top-text {
    margin: 0 21px;
    font-size: 24px;
    color: #FFFFFF;
  }
}

.script-content {
  margin: 51px 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: hidden;

  ::v-deep .el-scrollbar {
    flex: 1;
    height: 1%;

    .el-scrollbar__wrap {
      overflow-x: hidden;

      .el-scrollbar__view {
        height: 100%;
        margin-left: 70px;
      }
    }

    .el-scrollbar__bar.is-vertical {
      .el-scrollbar__thumb {
        width: 8px;
        background: #4B45FF;
      }
    }
  }

  .script-item {
    position: relative;
    cursor: grab;

    /*&::before {*/
    /*    position: absolute;*/
    /*    width: 1px;*/
    /*    height: 100%;*/
    /*    content: '';*/
    /*    left: 12px;*/
    /*    top: 26px;*/
    /*    background: #444797;*/
    /*}*/

    .title-content {
      display: flex;
      align-items: center;
      font-size: 18px;

      .title-left {
        width: 26px;
        height: 26px;
        background: #BFBFBF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title-right {
        margin-left: 12px;
      }
    }

    .active-title {

      .title-left {
        background: #F49D19;
      }
    }

    .children-content {
      margin-left: 12px;
      border-left: 1px dashed #444797;
      padding: 20px 0 20px 28px;

      .script-subItem {
        /*margin-top: 20px;*/
        width: 90%;
        word-break: break-all;
        line-height: 40px;
        opacity: 0.3;
        padding: 10px;
      }
    }
  }

  .script-title {
    margin-left: 60px;
    font-size: 18px;
  }

  .script-inner {
    width: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-align: left;
    margin-left: 60px;

    ::v-deep .el-scrollbar__wrap {
      width: 100%;
      margin-right: -40px !important;
    }

    .inner-keywords {
      width: 100%;
    }

    .inner-main {
      width: 80%;
      margin-top: 30px;
    }
  }
}

.isCurrent {
  opacity: 1 !important;
  font-size: 16px;
  color: #4B45FF;
  background: rgba(10, 8, 73, 0.5);
}

.script-content {
  margin: 51px 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: hidden;

  ::v-deep .el-scrollbar {
    flex: 1;
    height: 1%;

    .el-scrollbar__wrap {
      overflow-x: hidden;

      .el-scrollbar__view {
        height: 100%;
        margin-left: 70px;
      }
    }

    .el-scrollbar__bar.is-vertical {
      .el-scrollbar__thumb {
        width: 8px;
        background: #4B45FF;
      }
    }
  }

  .script-item {
    position: relative;
    cursor: grab;

    /*&::before {*/
    /*    position: absolute;*/
    /*    width: 1px;*/
    /*    height: 100%;*/
    /*    content: '';*/
    /*    left: 12px;*/
    /*    top: 26px;*/
    /*    background: #444797;*/
    /*}*/

    .title-content {
      display: flex;
      align-items: center;
      font-size: 18px;

      .title-left {
        width: 26px;
        height: 26px;
        background: #BFBFBF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title-right {
        margin-left: 12px;
      }
    }

    .active-title {

      .title-left {
        background: #F49D19;
      }
    }

    .children-content {
      margin-left: 12px;
      border-left: 1px dashed #444797;
      padding: 20px 0 20px 28px;

      .script-subItem {
        /*margin-top: 20px;*/
        width: 90%;
        word-break: break-all;
        line-height: 40px;
        opacity: 0.3;
        padding: 10px;
        min-height: 60px;

        .subItem-time {
          position: absolute;
          left: -75px;
          text-align: right;
          width: 60px;
        }
      }
    }
  }
}

.color-word {
  color: #4B45FF;
}
</style>